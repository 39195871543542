import React, { useEffect, useState } from 'react';
import * as PUIDocumentUploader from '@elliemae/pui-doc-uploader';
import { EventHandler, Store } from '@elliemae/encw-host-utils';
import CUSTOM_EVENTS from '../../data/constants/events';
import WindowService from '../../data/services/window-service';

const { DocUploader } = PUIDocumentUploader;

const DocumentUploaderModal = () => {
  const [isOpen, setShowModal] = useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    EventHandler.addListener(CUSTOM_EVENTS.DOCUMENT_UPLOADER, handleListener);
  }, []);
  const handleListener = ({ detail }) => {
    setRows(detail.rows);
    setShowModal(true);
  };
  const handleOnCancel = (data) => {
    EventHandler.create(CUSTOM_EVENTS.SHOW_SPINNER, { show: false });
    EventHandler.create(CUSTOM_EVENTS.DOCUMENT_UPLOADER_RESPONSE, data);
    setShowModal(false);
  };
  const { scope } = Store.getValue(Store.KEYS.PARTNER_INFO);
  const entityRef = scope ? scope.entityRef : {};

  return (
    isOpen && (
      <DocUploader
        authToken={Store.getValue(Store.KEYS.AUTHORIZATION)}
        endpoint={`${Store.getValue(Store.KEYS.BASE_DOMAIN)}`}
        entityRef={entityRef}
        onAuthTokenFailure={() =>
          new Promise((resolve) => {
            const accessToken = WindowService.getAccessToken();
            Store.setValue(Store.KEYS.AUTHORIZATION, accessToken);
            resolve(accessToken);
          })
        }
        onCancel={handleOnCancel}
        open
        rows={rows}
      />
    )
  );
};

export default DocumentUploaderModal;
