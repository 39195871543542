/* eslint-disable no-console */
import {
  Store,
  TransactionHelper,
  RequestService,
  EventHandler,
} from '@elliemae/encw-host-utils';
import CUSTOM_EVENTS from '../constants/events';
import { MODAL_SIZES } from '../constants/dimsum';
import WindowService from './window-service';
import APIS from '../config/apis';
import { SUCCESS_MESSAGE } from '../constants/config';
/*
  This logic will be executed when window.clearUserSession is called from the Smart client.
  this function is exposed on publishObjects method on index.js
*/
const UtilsService = {
  close: async () => {
    if (!WindowService.apiFinished) {
      return;
    }
    const dateObj = new Date();
    // eslint-disable-next-line prettier/prettier
    console.log('Current Time ', Date.now(), ' ', dateObj.getHours(), ' ', dateObj.getMinutes(), ' ', dateObj.getSeconds())
    console.log('close function is triggered');
    const serviceOrderIds = Store.getValue(Store.KEYS.SERVICE_ORDER_IDS);
    if (serviceOrderIds && serviceOrderIds.length > 0) {
      const promises = serviceOrderIds.map(async (serviceOrderId) => {
        console.log('Before calling SessionManager API');
        const url = APIS.closeTransaction(serviceOrderId);
        const payload = TransactionHelper.closePayload();
        const response = await RequestService.post(
          url,
          Store.getValue(Store.KEYS.AUTHORIZATION),
          payload,
        );
        console.log('After calling SessionManager API');
        return response;
      });

      await Promise.all(promises).then((response) => {
        console.log(response);
      });

      console.log('Before Store.setValue(Store.KEYS.SERVICE_ORDER_IDS, null)');
      Store.setValue(Store.KEYS.SERVICE_ORDER_IDS, null);
      console.log('After Store.setValue(Store.KEYS.SERVICE_ORDER_IDS, null)');
    }
    console.log(
      'Before setClearSessionFlag window.encompassSession.cleared=',
      window.encompassSession.cleared,
    );
    WindowService.setClearSessionFlag(true);
    console.log(
      'Aftert setClearSessionFlag window.encompassSession.cleared=',
      window.encompassSession.cleared,
    );
    console.log('return now');
    // eslint-disable-next-line consistent-return
    return { message: SUCCESS_MESSAGE.TRANSACTION_CLOSED };
  },
  onResponseError: async (response) => {
    let errorDetail = await response.json();
    errorDetail = errorDetail.details || response.statusText;
    if (response.status >= 500) {
      // render error modal should be handler by EventHandler
      EventHandler.create(CUSTOM_EVENTS.EXCEPTION_HANDLER, {
        errorModalTitle: '',
        errorModalMessage: errorDetail,
        handleCancel: this.close,
        modalSize: MODAL_SIZES.LARGE,
      });
    }
    return Promise.reject(errorDetail);
  },
};

export default UtilsService;
