import React, { useState, useEffect } from 'react';
import DSModal from '@elliemae/ds-modal';
import { EventHandler } from '@elliemae/encw-host-utils';
import { MODAL_SIZES } from '../../data/constants/dimsum';
import CUSTOM_EVENTS from '../../data/constants/events';
import { ObjectStyled, IframeStyled, ImageContainerStyled } from './styles';

const NO_PREVIEW_CONTENT = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const ViewerModal = () => {
  const [documentURL, setDocumentURL] = useState('');
  const [fileType, setFileType] = useState('');
  const [hasPreview, setHasPreview] = useState(true);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    EventHandler.addListener(CUSTOM_EVENTS.OPEN_MODAL, handleListener);
  }, []);
  const handleListener = ({ detail }) => {
    setDocumentURL(detail.documentURL);
    setFileType(detail.fileType);
    setHasPreview(!NO_PREVIEW_CONTENT.includes(detail.fileType));
    setShowModal(true);
  };
  const handleOnConfirm = () => {
    setShowModal(false);
  };
  const buildElement = () => {
    let element;
    if (fileType.search('image/') !== -1) {
      element = (
        <ImageContainerStyled>
          <img
            src={documentURL}
            alt={documentURL}
            style={{ maxWidth: 'initial' }}
          />
        </ImageContainerStyled>
      );
    } else if (hasPreview) {
      element = (
        <ObjectStyled data={documentURL} type={fileType}>
          {documentURL}
        </ObjectStyled>
      );
    } else {
      element = <IframeStyled src={documentURL} title="file" />;
    }
    return element;
  };
  return (
    showModal && (
      <DSModal
        isOpen
        modalTitle="Document Viewer"
        onClose={handleOnConfirm}
        onConfirm={handleOnConfirm}
        confirmLabel="Cancel"
        size={MODAL_SIZES.LARGE}
      >
        {buildElement()}
      </DSModal>
    )
  );
};

export default ViewerModal;
