import React from 'react';
import DSModal, { MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from '@elliemae/ds-modal';
import propTypes from 'prop-types';
import { ModalBody } from './styles';

const Alert = ({ isOpen, title, text, onClose, size, style, subTypeIcon }) => (
  <DSModal
    centered
    isOpen={isOpen}
    modalSubType={subTypeIcon || MODAL_SUB_TYPE_V2.ERROR}
    modalTitle={title}
    modalType={MODAL_TYPE_V2.INFORMATION}
    onClose={onClose}
    confirmLabel="Back"
    size={size || 'large'}
    version={2}
    style={style}
  >
    <ModalBody>{text}</ModalBody>
  </DSModal>
);

Alert.propTypes = {
  isOpen: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  size: propTypes.string,
  style: propTypes.object,
  subTypeIcon: propTypes.string,
};

export default Alert;
