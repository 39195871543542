import {
  CredentialsHelper,
  ResourceHelper,
  ActionHelper,
  EventHandler,
} from '@elliemae/encw-host-utils';
import CUSTOM_EVENTS from './events';

const getSupportedActions = () => [
  {
    name: ActionHelper.NAMES.GET_AVAILABLE_RESOURCES,
    preProcessingFn: () => ResourceHelper.getAvailableResources(),
    postProcessingFn: undefined,
  },
  {
    name: ActionHelper.NAMES.UPDATE_CREDENTIALS,
    preProcessingFn: () => CredentialsHelper.updateCredentials(),
    postProcessingFn: async (params) => {
      const {
        serviceSetup,
        credentials,
        handleCancel,
        scheme,
        listingName,
        productCredentials,
      } = params;
      // Check if it's the cancel flow
      if (handleCancel) return handleCancel;
      EventHandler.create(CUSTOM_EVENTS.SHOW_SPINNER, { show: true });
      // Call save credentials API (Utils package)
      const response = await CredentialsHelper.saveCredentials(
        serviceSetup,
        credentials,
        scheme,
        listingName,
        productCredentials,
      );
      EventHandler.create(CUSTOM_EVENTS.SHOW_SPINNER, { show: false });
      return response;
    },
  },
];

export default getSupportedActions;
