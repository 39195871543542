import { Store } from '@elliemae/encw-host-utils';

export default {
  closeTransaction: (serviceOrderId) => `${Store.getValue(
    Store.KEYS.BASE_DOMAIN,
  )}/encservices/v1/serviceOrders/${serviceOrderId}/sessionManager
  `,
  createTransaction: (loanId) =>
    `${Store.getValue(
      Store.KEYS.BASE_DOMAIN,
    )}/encservices/v1/loans/${loanId}/serviceOrders`,
  setTransaction: (loanId, transactionId) => `${Store.getValue(
    Store.KEYS.BASE_DOMAIN,
  )}/encservices/v1/loans/${loanId}/serviceOrders?transactionId=${transactionId}
  `,
  updateTransaction: (loanId, serviceOrderId) => `${Store.getValue(
    Store.KEYS.BASE_DOMAIN,
  )}/encservices/v1/loans/${loanId}/serviceOrders/${serviceOrderId}
  `,
};
