/* eslint-disable max-lines */
import React from 'react';
import { EventHandler } from '@elliemae/encw-host-utils';
import { ServiceCredentialsManager } from '@elliemae/pui-app-widgets/dist/service-credentials-manager';
import _ from 'lodash';
import { MODAL_SUB_TYPE_V2 } from '@elliemae/ds-modal';
import CUSTOM_EVENTS from '../../data/constants/events';
import { BANNER_TYPES, MODAL_SIZES } from '../../data/constants/dimsum';
import {
  UNABLE_LOGIN,
  UPDATE_CREDENTIALS,
  GENERIC_ERROR,
} from '../../data/constants/messages';

class UpdateCredentialsModal extends React.Component {
  constructor() {
    super();
    this.state = this.getInitialState();
    EventHandler.addListener(
      CUSTOM_EVENTS.UPDATE_CREDENTIALS,
      this.handleListener,
    );
  }

  componentDidUpdate() {
    if (this.state.noSchema) {
      setTimeout(() => {
        EventHandler.create(CUSTOM_EVENTS.UPDATE_CREDENTIALS_RESPONSE, {
          serviceSetup: { noSchema: true },
          credentials: {},
        });
      }, 50);
    }
  }

  getInitialState = () => ({
    showModal: false,
    scheme: {},
    message: null,
    bannerType: BANNER_TYPES.WARNING,
    schemeValues: {},
    attempts: 0,
    serviceSetup: {},
    noSchema: false,
    listingName: '',
    productCredentials: {},
  });

  setBanner = (bannerType, message) => {
    this.setState({ message, bannerType });
  };

  // Check if company credentials are overriden
  checkCredOverridden = (serviceCredential) => {
    const {
      userCredentials: [credential],
    } = serviceCredential;
    const { useCompanyCredential } = credential;
    if (!useCompanyCredential) {
      this.showUnableToLoginModal(UNABLE_LOGIN.TITLE, UNABLE_LOGIN.TEXT);
      return true;
    }
    return false;
  };

  // Set user credentials modal if company credentials are required
  setCompanyCredReq = (serviceCredential, credentialId, schema) => {
    const { attempts } = this.state;
    const { companyCredential } = serviceCredential;
    if (_.isEmpty(companyCredential) || _.isEmpty(schema)) {
      // Unable to login
      this.showUnableToLoginModal(UNABLE_LOGIN.TITLE, UNABLE_LOGIN.TEXT);
      return false;
    }
    if (credentialId) {
      const isOverridden = this.checkCredOverridden(serviceCredential);
      if (isOverridden) {
        return false;
      }
      if (attempts) {
        // Red banner with special admin message
        this.setBanner(BANNER_TYPES.DANGER, UPDATE_CREDENTIALS.UNABLE_LOGIN);
      } else {
        // Yellow banner
        this.setBanner(BANNER_TYPES.WARNING, UPDATE_CREDENTIALS.LOGIN_FAILED);
      }
    } else if (attempts) {
      // Red banner with special admin message
      this.setBanner(BANNER_TYPES.DANGER, UPDATE_CREDENTIALS.UNABLE_LOGIN);
    }
    return true;
  };

  // Set user credentials modal if company credentials are optional
  setCompanyCredOpt = (serviceCredential, credentialId, companySchema) => {
    const { attempts } = this.state;
    if (credentialId) {
      const isOverridden = this.checkCredOverridden(serviceCredential);
      if (isOverridden) {
        return false;
      }
      if (attempts) {
        // Check if company level fields exists in schema
        const message = _.isEmpty(companySchema)
          ? UPDATE_CREDENTIALS.INVALID_CREDENTIALS // Red banner
          : UPDATE_CREDENTIALS.UNABLE_LOGIN; // Red banner with special admin message
        this.setBanner(BANNER_TYPES.DANGER, message);
      } else {
        // Yellow banner
        this.setBanner(BANNER_TYPES.WARNING, UPDATE_CREDENTIALS.LOGIN_FAILED);
      }
    } else if (attempts) {
      // Check if company level fields exists in schema
      const message = _.isEmpty(companySchema)
        ? UPDATE_CREDENTIALS.INVALID_CREDENTIALS // Red banner
        : UPDATE_CREDENTIALS.UNABLE_LOGIN; // Red banner with special admin message
      this.setBanner(BANNER_TYPES.DANGER, message);
    }
    return true;
  };

  handleListener = ({ detail }) => {
    // Check API errors
    const { error } = detail;
    if (error) {
      this.showError(GENERIC_ERROR.TITLE, GENERIC_ERROR.TEXT, { error });
      return;
    }
    // Multiple user credentials
    const { credentialId, isCredentialShared } = detail;
    if (credentialId && isCredentialShared) {
      this.showUnableToLoginModal(UNABLE_LOGIN.TITLE, UNABLE_LOGIN.TEXT);
      return;
    }
    const {
      schema,
      companyCredRequired,
      serviceSetup,
      companySchema,
      listingName,
      productCredentials,
    } = detail;
    // check is schema is defined --> if not show a JS error
    if (!schema) {
      this.setState({ noSchema: true });
      return;
    }
    const { serviceCredential } = serviceSetup;
    if (
      (_.isEmpty(serviceCredential) && companyCredRequired) ||
      _.isEmpty(schema)
    ) {
      // Unable to login
      this.showUnableToLoginModal(UNABLE_LOGIN.TITLE, UNABLE_LOGIN.TEXT);
      return;
    }
    const show = companyCredRequired
      ? this.setCompanyCredReq(serviceCredential, credentialId, schema)
      : this.setCompanyCredOpt(serviceCredential, credentialId, companySchema);
    if (show) {
      // Load previous values of fields
      const scheme = this.getSchemeValues(schema);
      this.setState({
        scheme,
        showModal: true,
        serviceSetup,
        listingName,
        productCredentials,
      });
    }
  };

  // Retrieve values from user loaded fields
  getSchemeValues = (schema) => {
    const { schemeValues } = this.state;
    return schema.map((field) => {
      const defaultValue = schemeValues[field.id];
      return { ...field, defaultValue };
    });
  };

  // Submit credentials form
  handleOnSubmit = (credentials) => {
    const {
      attempts,
      serviceSetup,
      scheme,
      listingName,
      productCredentials,
    } = this.state;
    this.setState({ attempts: attempts + 1 });
    this.setState({ schemeValues: credentials });
    // Call save credentials API (Post processing fn in actions file)
    EventHandler.create(CUSTOM_EVENTS.UPDATE_CREDENTIALS_RESPONSE, {
      credentials,
      serviceSetup,
      scheme,
      listingName,
      productCredentials,
    });
    this.setState({ showModal: false });
  };

  // Show error modal
  showError = (title, text, response) => {
    EventHandler.create(CUSTOM_EVENTS.EXCEPTION_HANDLER, {
      errorModalTitle: title,
      errorModalMessage: text,
      handleCancel: () => this.handleOnCancel(response),
      modalSize: MODAL_SIZES.SMALL,
    });
  };

  // Show custom error modal
  showUnableToLoginModal = (title, text, response) => {
    EventHandler.create(CUSTOM_EVENTS.EXCEPTION_HANDLER, {
      errorModalTitle: title,
      errorModalMessage: text,
      handleCancel: () => this.handleOnCancel(response),
      modalSize: MODAL_SIZES.SMALL,
      customStyle: { maxWidth: 440 },
      subTypeIcon: MODAL_SUB_TYPE_V2.WARNING,
    });
  };

  // Cancel flow
  handleOnCancel = (response = {}) => {
    EventHandler.create(CUSTOM_EVENTS.UPDATE_CREDENTIALS_RESPONSE, {
      handleCancel: response,
    });
    this.setState({ showModal: false });
  };

  render() {
    const { showModal, message, bannerType, scheme } = this.state;
    return (
      showModal && (
        <ServiceCredentialsManager
          bannerMessage={message}
          bannerType={bannerType}
          isOpen
          onClose={() => this.handleOnCancel()}
          onReject={() => this.handleOnCancel()}
          onSubmit={this.handleOnSubmit}
          schema={scheme}
          text={UPDATE_CREDENTIALS.SAVE_CREDENTIALS}
        />
      )
    );
  }
}

export default UpdateCredentialsModal;
