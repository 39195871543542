/*
  Logic related to the events called from SC
  This is not handled from our application. We only expose methods.
  Any questions, talk to David Hamm
*/
const WindowService = {
  exposeClose: (fn) => {
    window.clearUserSession = fn;
  },
  // It's not used. We should figure out if it only calls from SC,
  // or if we should add this somewhere in our application
  clearUserSession: () => {
    window.clearUserSession();
  },
  setClearSessionFlag: (flag) => {
    try {
      window.encompassSession.cleared = flag;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },
  // TransactionSC.close()
  close: () => {
    window.encompassSession.Close();
  },
  getAccessToken: () => window.encompassSession.GetAccessToken(),
  apiFinished: true,
};

export default WindowService;
