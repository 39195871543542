import { Transaction } from '@elliemae/encw-host-transaction';
import WindowService from '../data/services/window-service';

let transaction;

const getTransaction = (authToken, partnerInfo, originInfo) => {
  if (transaction) return transaction;
  transaction = new Transaction(authToken, partnerInfo, originInfo);
  return transaction;
};

const close = () => {
  const tx = getTransaction();
  tx.close();
  WindowService.close();
};

export { getTransaction, close };
