export const APP_UNAVAILABLE = {
  TITLE: 'Application Unavailable',
  TEXT:
    'We are unable to launch this application at this time. Please talk to your administrator or check back later.',
};

export const UNABLE_LOGIN = {
  TITLE: 'We were not able to log you in.',
  TEXT:
    'Your administrator has either not provided credentials, or has provided incorrect credentials for your use. Please reach out to your administrator to resolve the issue.',
};

export const GENERIC_ERROR = {
  TITLE: 'Unable to login',
  TEXT: 'Something went wrong. Please try again. ',
};

export const UPDATE_CREDENTIALS = {
  LOGIN_FAILED: 'Login failed. Enter your credentials below.',
  INVALID_CREDENTIALS: 'Invalid credentials. Please try again.',
  UNABLE_LOGIN: `Still unable to log into this provider. If you think your credentials are correct, 
    your administrator might need to check settings for this service provider.`,
  SAVE_CREDENTIALS: 'These credentials will be saved for future use.',
};
