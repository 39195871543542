import React, { useEffect, useState } from 'react';
import { EventHandler } from '@elliemae/encw-host-utils';
import Alert from '../alert';
import CUSTOM_EVENTS from '../../data/constants/events';
import { MODAL_SIZES } from '../../data/constants/dimsum';

const ExceptionHandler = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [onCancel, setOnCancel] = useState(null);
  const [size, setSize] = useState(MODAL_SIZES.SMALL);
  const [style, setStyle] = useState(null);
  const [typeIcon, setTypeIcon] = useState(null);
  const handleListener = ({ detail }) => {
    const {
      errorModalMessage,
      errorModalTitle,
      handleCancel,
      customStyle,
      subTypeIcon,
    } = detail;
    setErrorMessage(errorModalMessage);
    setErrorTitle(errorModalTitle);
    setStyle(customStyle);
    setTypeIcon(subTypeIcon);
    if (handleCancel) {
      setOnCancel(() => handleCancel);
    }
    const { modalSize } = detail;
    if (modalSize) {
      setSize(modalSize);
    }
    setShowModal(true);
  };
  const handleOnCloseModal = () => {
    setShowModal(false);
    if (onCancel) {
      onCancel();
    }
  };
  useEffect(() => {
    EventHandler.addListener(CUSTOM_EVENTS.EXCEPTION_HANDLER, handleListener);
  }, []);
  return (
    <Alert
      isOpen={showModal}
      onClose={handleOnCloseModal}
      size={size}
      text={errorMessage}
      title={errorTitle}
      style={style}
      subTypeIcon={typeIcon}
    />
  );
};

export default ExceptionHandler;
