import React, { useState, useEffect } from 'react';
import { EventHandler } from '@elliemae/encw-host-utils';
import DSLoadingIndicator from '@elliemae/ds-basic/LoadingIndicator';
import styled from 'styled-components';
import CUSTOM_EVENTS from '../../data/constants/events';

const DEFAULT_MESSAGE = 'Loading...';

const StyledSpinner = styled(DSLoadingIndicator)`
  color: white;
  font-size: 1.5em;
`;

const Spinner = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(DEFAULT_MESSAGE);
  const handleListener = ({ detail }) => {
    const { text, show } = detail;
    setMessage(text || DEFAULT_MESSAGE);
    setLoading(show);
  };
  useEffect(() => {
    EventHandler.addListener(CUSTOM_EVENTS.SHOW_SPINNER, handleListener);
  }, []);
  return <StyledSpinner loading={loading} message={message} size="m" />;
};

export default Spinner;
