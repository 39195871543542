/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
// Needed for redux-saga es6 generator support
/* eslint-disable import/first */
import 'utils/publicPath';
import 'utils/preload';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import 'sanitize.css/sanitize.css';
import '@elliemae/ds-basic/css/dimsum.css';
import {
  history as defaultHistory,
  configureStore,
  enableReactAppForHostIntegration,
  getAppConfigValue,
  AppRoot,
  setAppConfig,
  listenStorageEvents,
} from '@elliemae/pui-app-sdk';
import Spinner from 'view/spinner';
import LandingPage from 'view/landing';
import InterceptorService from 'data/services/interceptor-service';
import { HOST_CONTRACT, PARTNER_CONTRACT } from './data/constants/config';
// intercepts error 401 (Unauthorized) and we renew the token
InterceptorService.setInterceptor(401);
listenStorageEvents();
// eslint-disable-next-line no-undef
setAppConfig(APP_CONFIG);

const initialState = {};
let defaultStore = null;
let appNode = null;
let appHistory = null;
let data = {};

const render = () => {
  ReactDOM.render(
    <AppRoot store={defaultStore} history={appHistory}>
      {data && <LandingPage hostData={data} />}
      {!data && <Spinner />}
    </AppRoot>,
    appNode,
  );
};

const setData = async (hostData, partnerData) => {
  // eslint-disable-next-line no-param-reassign
  const { serviceOrderId, transactionId } = hostData;

  hostData.partnerInfo = {
    ...partnerData,
    ...(serviceOrderId && { serviceOrderId }),
    ...(transactionId && { transactionID: transactionId }),
  };

  data = hostData;
};
const getLocalToken = async () => {
  // eslint-disable-next-line no-alert
  const token = await window.prompt('New Token', '');
  return token;
};
const loadData = () => {
  const {
    location: { search },
  } = window;
  if (search.includes('mockContract=true')) {
    getLocalToken().then((token) => {
      HOST_CONTRACT.authToken = `Bearer ${token}`;
      setData(HOST_CONTRACT, parsePartnerData(PARTNER_CONTRACT));
      return true;
    });
  }
  return false;
};

const parsePartnerData = (partnerData) => {
  const entityRef = {
    entityId: partnerData.scopes[0].entityRef.entityId,
    entityType: partnerData.scopes[0].entityRef.entityType,
  };
  const entityIdArr = entityRef.entityId.split(':');
  return {
    loanId: entityIdArr[entityIdArr.length - 1],
    serviceSetupId: partnerData.serviceSetupId,
    entityRef,
    providerId: partnerData.serviceSetup.providerId,
    scope: {
      entityRef,
      orderServiceFor: partnerData.scopes[0].canOrderServiceFor[0],
    },
  };
};

const onInit = ({
  containerId = 'app-container',
  hostData,
  partnerData,
  history = defaultHistory,
  store,
}) => {
  // Testing SC on web app
  const useMockData = loadData();
  // window.emInteractionStr = this.parseDataFromSmartClient
  if (!useMockData && hostData && partnerData) {
    setData(hostData, parsePartnerData(partnerData));
  }
  // Create redux store with history
  appHistory = history;
  defaultStore = store;
  if (!defaultStore) defaultStore = configureStore(initialState, history);
  appNode = document.getElementById(containerId);
};

const onMount = () => {
  if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['view/landing'], () => {
      const { unmount } = (window.emui || {})[getAppConfigValue('appId')] || {};
      if (unmount) unmount();
      render();
    });
  }
  render();
};

enableReactAppForHostIntegration({ onInit, onMount });

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    if (!registrations || (registrations && registrations.length === 0)) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register(`${getPublicPath()}js/sw.js`)
          .then((registration) => {
            console.log('SW registered: ', registration);
          })
          .catch((registrationError) => {
            console.error('SW registration failed: ', registrationError);
          });
      });
    }
  });
}
