const CUSTOM_EVENTS = {
  UPDATE_CREDENTIALS: 'updateCredentials',
  UPDATE_CREDENTIALS_RESPONSE: 'updateCredentialsResponse',
  EXCEPTION_HANDLER: 'openErrorModal',
  DOCUMENT_UPLOADER: 'getAvailableResources',
  DOCUMENT_UPLOADER_RESPONSE: 'getAvailableResourcesResponse',
  OPEN_MODAL: 'openModal',
  SHOW_SPINNER: 'showSpinner',
};

export default CUSTOM_EVENTS;
