export const INTEGRATION_CONTAINER = 'integrationContainer';

export const LOCAL_URL = 'http://localhost:5000';
export const ENC_SERVICES_URL = '/encompass/v3/';
export const ENCOMPASS_SERVICES_URL = '/encservices/v1/';

export const LOCAL_PATH = 'mockContract';
export const LOCALHOST = 'localhost';
export const SW_URL = 'https://int-ha-2-showcase.ssf.epc.rd.elliemae.io/';

export const ERROR_MESSAGE = {
  NOT_TRANSACTION_CONTEXT:
    'Close transaction must be executed in the context of an existing transaction.',
  NOT_TRANSACTION_CONTEXT_UPDATE:
    'A transaction update must be initiated in the context of an existing transaction.',
};
export const SUCCESS_MESSAGE = {
  TRANSACTION_CLOSED: 'Transaction Closed Successfully.',
};

export const HOST_CONTRACT = {
  mockData: true,
  authToken: 'Bearer USfvDEIfJwBBbOWU7S8fewiGXOGh',
  environment: 'https://int.api.ellielabs.com',
  hostApplication: 'ENC',
};

export const PARTNER_CONTRACT = {
  serviceSetupId: 'd58c6dc7-414c-4f0a-93fa-49eaa0cd408a',
  scopes: [
    {
      entityRef: {
        entityId:
          'urn:elli:encompass:DEBE11170913:loan:4052f33a-1494-42b6-adfb-d17da2350a5e',
        entityType: 'urn:elli:encompass:loan',
        legacyId:
          'urn:elli:encompass:DEBE11170913:loan:4052f33a-1494-42b6-adfb-d17da2350a5e',
      },
      canOrderServiceFor: ['LOAN'],
    },
  ],
  serviceSetup: {
    id: 'd58c6dc7-414c-4f0a-93fa-49eaa0cd408a',
    name: 'Test-ServiceSetup-ssf-showcase',
    description: 'Test Service Setup for ssf-showcase',
    instanceId: 'DEBE11170913',
    orderType: 'MANUAL',
    isActive: true,
    productName: 'ssf-showcase21',
    providerId: '33a5bf4a-b095-4a02-8f32-e433fa140c06',
    category: 'Appraisal',
    partnerId: '007001',
    rank: 1,
    authorizedCredentials: [],
    authorizedUsers: [],
    preConfiguredOptions: [],
    scope: 'Loan',
  },
};

export const SIZES = {
  M: 'medium',
  L: 'large',
  S: 'small',
};

export const APP_UNAVAILABLE = {
  TITLE: 'Application Unavailable',
  TEXT:
    'We are unable to launch this application at this time. Please talk to your administrator or check back later',
};

export const CUSTOM_EVENTS = {
  UPDATE_CREDENTIALS_REQUEST: 'updateCredentials',
  UPDATE_CREDENTIALS_RESPONSE: 'updateCredentialsResponse',
  GET_AVAILABLE_RESOURCES: 'getAvailableResources',
  OPEN_MODAL: 'openModal',
  OPEN_ERROR_MODAL: 'openErrorModal',
};

export const BANNER_TYPES = {
  DANGER: 'danger',
  WARNING: 'warning',
};
