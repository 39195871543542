/* eslint-disable max-lines */
/**
 *
 * LandingPage
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import propTypes from 'prop-types';
import * as ssf from '@elliemae/em-ssf-host';
import { Store, OriginHelper, EventHandler } from '@elliemae/encw-host-utils';
import { Http } from '@elliemae/encw-host-http';
import { Auth } from '@elliemae/encw-host-auth';
import { Application } from '@elliemae/encw-host-application';
import Home from '../home';
import Spinner from '../spinner';
import ExceptionHandler from '../exceptionhandler';
import getSupportedActions from '../../data/constants/actions';
import { close } from '../../utils/transaction';
import CUSTOM_EVENTS from '../../data/constants/events';
import { MODAL_SIZES } from '../../data/constants/dimsum';
import { APP_UNAVAILABLE } from '../../data/constants/messages';
import TransactionSC from '../../data/objects/transaction-sc';
import UtilsService from '../../data/services/utils-service';
import WindowService from '../../data/services/window-service';
import { ScriptContainer } from './styles';

const PARTNER_ELEMENT = 'scriptContainer';

const LOCAL_URL = 'http://localhost:5000';
let guestReadyTimeout;

const LandingPage = ({ hostData }) => {
  const [renderComponents, setRenderComponents] = useState(false);
  const renderError = () => {
    EventHandler.create(CUSTOM_EVENTS.EXCEPTION_HANDLER, {
      errorModalTitle: APP_UNAVAILABLE.TITLE,
      errorModalMessage: APP_UNAVAILABLE.TEXT,
      handleCancel: close,
      modalSize: MODAL_SIZES.LARGE,
    });
  };
  const onInit = async () => {
    const {
      hostApplication,
      partnerInfo,
      authToken,
      environment,
      // adminInterfaceUrl,
    } = hostData;
    Store.setValue(Store.KEYS.BASE_DOMAIN, environment);
    const origin = await OriginHelper.createOrigin(partnerInfo, authToken, 2);
    saveDataInStores(hostApplication, authToken, partnerInfo);
    if (origin.error) {
      document.getElementById('em-wait-message').style.display = 'none';
      EventHandler.create(CUSTOM_EVENTS.SHOW_SPINNER, { show: false });
      return renderError();
    }
    guestReadyTimeout = setTimeout(() => {
      EventHandler.create(CUSTOM_EVENTS.SHOW_SPINNER, { show: false });
      document.getElementById('em-wait-message').style.display = 'none';
      return renderError();
    }, 30000);
    return renderGuest({ hostApplication, partnerInfo, origin });
  };
  useEffect(() => {
    onInit();
  }, []);
  const renderGuest = ({ hostApplication, partnerInfo, origin }) => {
    const guestCallback = () => {
      clearTimeout(guestReadyTimeout);
      document.getElementById('em-wait-message').style.display = 'none';
      EventHandler.create(CUSTOM_EVENTS.SHOW_SPINNER, { show: false });
    };
    const host = new ssf.Host(hostApplication, {
      // ssf.ready callback
      readyStateCallback: guestCallback,
    });
    // origin validation
    const originInfo = OriginHelper.getOriginInfo(origin, partnerInfo);
    // publish objects (Auth, Http, TransactionSC, Application)
    publishObjects({ host, originInfo, hostData });
    const { hostname } = window.location;
    // render parnet url or showcase for testing purpose
    const guestUrl = hostname === 'localhost' ? LOCAL_URL : origin.interfaceUrl;
    const guestArea = window.document.getElementById(PARTNER_ELEMENT);
    host.renderGuest({}, guestUrl, guestArea, { allowSameOrigin: true });
    // render Home component
    setRenderComponents(true);
  };
  /* This method is different from that used in HA Web.
     We are using TransactionSC and exposing logic to SC through WindowService and UtilsService
     Be cautious about any changes.
  */
  const publishObjects = ({ host, originInfo, hostData: data }) => {
    const { hostApplication, partnerInfo, authToken } = data;
    const auth = new Auth(partnerInfo, authToken);
    host.publish(auth);
    const http = new Http();
    host.publish(http);
    // SC: TransactionSC creation
    const transaction = TransactionSC.new(authToken, partnerInfo, originInfo);
    // clearUserSession function exposed
    WindowService.exposeClose(UtilsService.close);
    host.publish(transaction);
    const descriptor = {
      id: hostApplication,
      name: hostApplication,
    };
    const application = new Application(
      descriptor,
      partnerInfo,
      authToken,
      getSupportedActions(this),
    );
    host.publish(application);
  };
  const saveDataInStores = (hostApplication, authToken, partnerInfo) => {
    Store.setValue(Store.KEYS.HOST_APPLICATION, hostApplication);
    Store.setValue(Store.KEYS.PARTNER_INFO, partnerInfo);
    Store.setValue(Store.KEYS.AUTHORIZATION, authToken);
  };
  return (
    <>
      <Spinner />
      <ExceptionHandler />
      {renderComponents && <Home />}
      <ScriptContainer id={PARTNER_ELEMENT} />
    </>
  );
};

LandingPage.propTypes = {
  hostData: propTypes.object.isRequired,
};

export default hot(LandingPage);
