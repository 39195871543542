import styled, { css } from 'styled-components';

const style = css`
  width: 100%;
  height: 70vh;
`;

export const ObjectStyled = styled.object`
  ${style}
`;

export const IframeStyled = styled.iframe`
  ${style}
`;

export const ImageContainerStyled = styled.div`
  ${style}
  overflow: scroll;
`;
