/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  RequestService,
  TransactionHelper,
  Store,
  EventHandler,
} from '@elliemae/encw-host-utils';
import { Transaction } from '@elliemae/encw-host-transaction';
import CUSTOM_EVENTS from '../constants/events';
import { MODAL_SIZES } from '../constants/dimsum';
import WindowService from '../services/window-service';
import APIS from '../config/apis';
import { ERROR_MESSAGE } from '../constants/config';

const TransactionSC = {
  /*
   Transaction object has special functionality in Smart Client
   therefore within this method, we override the create, update and set methods of the Transaction Object.
   */
  new: (authToken, partnerInfo, originInfo) => {
    function TranSC(authToken, partnerInfo, originInfo) {
      Transaction.call(this, authToken, partnerInfo, originInfo);
    }
    TranSC.prototype = Object.create(Transaction.prototype);
    TranSC.prototype.constructor = TranSC;
    // Transaction.create (Smart Client Functionality)
    TranSC.prototype.create = async function (requestdata) {
      const payload = TransactionHelper.createPayload(
        requestdata,
        this.partnerInfo,
      );
      const url = APIS.createTransaction(this.partnerInfo.loanId);
      try {
        WindowService.apiFinished = false;
        WindowService.setClearSessionFlag(false);
        const response = await RequestService.post(
          url,
          Store.getValue(Store.KEYS.AUTHORIZATION),
          payload,
        );
        if (!response.ok) {
          WindowService.apiFinished = true;
          return TransactionSC.onResponseError(response);
        }
        const location = response.headers.get('Location');
        const serviceOrderId =
          location && location.slice(location.indexOf('serviceOrders/') + 14);
        const serviceOrderIds =
          Store.getValue(Store.KEYS.SERVICE_ORDER_IDS) || [];
        Store.setValue(Store.KEYS.SERVICE_ORDER_IDS, [
          ...serviceOrderIds,
          serviceOrderId,
        ]);
        WindowService.apiFinished = true;
        const txId = await TransactionHelper.getTransactionId(
          Store.getValue(Store.KEYS.AUTHORIZATION),
          this.partnerInfo.loanId,
          serviceOrderId,
          TransactionSC.currentStatus,
        );
        this.partnerInfo = {
          ...this.partnerInfo,
          transactionID: txId,
          serviceOrderId,
        };
        Store.setValue(Store.KEYS.PARTNER_INFO, this.partnerInfo);
        return { id: txId };
      } catch (err) {
        WindowService.apiFinished = true;
        return TransactionSC.exceptionHandler(err);
      }
    };
    // Transaction.set (Smart Client Functionality)
    TranSC.prototype.set = async function (requestdata) {
      const payload = TransactionHelper.createPayload(
        requestdata,
        this.partnerInfo,
      );
      const txId = requestdata.id;
      const url = APIS.setTransaction(this.partnerInfo.loanId, requestdata.id);
      const { providerId } = this.partnerInfo;
      try {
        const response = await RequestService.get(
          url,
          Store.getValue(Store.KEYS.AUTHORIZATION),
          payload,
        );
        if (!response.ok) {
          return TransactionSC.onResponseError(response);
        }
        const body = await response.json();
        if (
          body.length &&
          body[0].providerId &&
          body[0].providerId === providerId
        ) {
          const serviceOrderId = body[0].id;
          this.partnerInfo = {
            ...this.partnerInfo,
            transactionID: txId,
            serviceOrderId,
          };
          Store.setValue(Store.KEYS.PARTNER_INFO, this.partnerInfo);
          const serviceOrderIds =
            Store.getValue(Store.KEYS.SERVICE_ORDER_IDS) || [];
          WindowService.setClearSessionFlag(false);
          if (!serviceOrderIds.find((id) => id === serviceOrderId)) {
            Store.setValue(Store.KEYS.SERVICE_ORDER_IDS, [
              ...serviceOrderIds,
              serviceOrderId,
            ]);
          }
          return {
            id: txId,
          };
        }
        return { message: 'Transaction ID is invalid' };
      } catch (error) {
        return TransactionSC.exceptionHandler(error);
      }
    };
    // Transaction.update (Smart Client Functionality)
    TranSC.prototype.update = async function (requestdata) {
      if (!this.partnerInfo.serviceOrderId) {
        return TransactionSC.exceptionHandler(
          ERROR_MESSAGE.NOT_TRANSACTION_CONTEXT_UPDATE,
          404,
        );
      }
      const getOptions = RequestService.getOptions(
        'GET',
        Store.getValue(Store.KEYS.AUTHORIZATION),
      );
      const payload = TransactionSC.updatePayload(requestdata);
      const url = APIS.updateTransaction(
        this.partnerInfo.loanId,
        this.partnerInfo.serviceOrderId,
      );
      try {
        // get previous transaction id status
        const getRes = await fetch(url, getOptions);

        if (!getRes.ok) {
          return TransactionSC.onResponseError(getRes);
        }

        const getServiceOrderId = await getRes.json();
        // update transaction
        const response = await RequestService.patch(
          url,
          Store.getValue(Store.KEYS.AUTHORIZATION),
          payload,
        );
        if (!response.ok) {
          return TransactionSC.onResponseError(response);
        }
        const location = response.headers.get('Location');
        // query service order if from header location
        const serviceOrderId =
          location && location.slice(location.indexOf('serviceOrders/') + 14);
        // update service order id in session storage
        this.partnerInfo = { ...this.partnerInfo, serviceOrderId };
        Store.setValue(Store.KEYS.PARTNER_INFO, this.partnerInfo);
        const serviceOrderIds =
          Store.getValue(Store.KEYS.SERVICE_ORDER_IDS) || [];
        // We skip to add the service order id which is in session storage already to avoid calling clear on the same
        // service order id more than once (update will not change service order id)
        if (serviceOrderIds.indexOf(serviceOrderId) === -1) {
          serviceOrderIds.push(serviceOrderId);
        }
        Store.setValue(Store.KEYS.SERVICE_ORDER_IDS, serviceOrderIds);
        WindowService.setClearSessionFlag(false);
        // get transaction id from the polling
        const txId = await TransactionHelper.getTransactionId(
          Store.getValue(Store.KEYS.AUTHORIZATION),
          this.partnerInfo.loanId,
          serviceOrderId,
          TransactionSC.currentStatus,
          getServiceOrderId.modifiedDate,
        );
        // update transaction id in session storage
        this.partnerInfo = { ...this.partnerInfo, transactionID: txId };
        Store.setValue(Store.KEYS.PARTNER_INFO, this.partnerInfo);
        return { id: txId };
      } catch (err) {
        return TransactionSC.exceptionHandler(err);
      }
    };
    // Transaction.close (Smart Client Functionality)
    TranSC.prototype.close = function () {
      WindowService.close();
    };
    TranSC.prototype.cancel = function () {
      // TODO: partial implementation until ECC defines what to do here
      WindowService.close();
    };
    TranSC.prototype.error = function () {
      // TODO: partial implementation until ECC defines what to do here
      WindowService.close();
    };
    const transaction = new TranSC(authToken, partnerInfo, originInfo);
    return transaction;
  },
  onResponseError: async (response) => {
    const errorDetail = await response.json();
    return TransactionSC.exceptionHandler(
      (errorDetail.errors &&
        errorDetail.errors.length &&
        errorDetail.errors[0].details) ||
        response.statusText,
      response.status,
    );
  },
  // eslint-disable-next-line consistent-return
  exceptionHandler: (response, status) => {
    if (status >= 500) {
      // this.context.renderErrorModal(response, '');
      EventHandler.create(CUSTOM_EVENTS.EXCEPTION_HANDLER, {
        errorModalTitle: '',
        errorModalMessage: response,
        handleCancel: {},
        modalSize: MODAL_SIZES.LARGE,
      });
    } else {
      return Promise.reject(response);
    }
  },
  updatePayload(data) {
    const payload = {};
    return data && data.request
      ? { ...payload, request: data.request }
      : payload;
  },
  currentStatus: () => Store.getValue(Store.KEYS.SERVICE_ORDER_IDS).length > 0,
};

export default TransactionSC;
