import { RequestService, Store } from '@elliemae/encw-host-utils';
import WindowService from './window-service';
import { ENC_SERVICES_URL, ENCOMPASS_SERVICES_URL } from '../constants/config';

const InterceptorService = {
  setInterceptor: (statusCode) =>
    RequestService.setInterceptor(
      statusCode,
      (url) =>
        url.search(ENC_SERVICES_URL) !== -1 ||
        url.search(ENCOMPASS_SERVICES_URL) !== -1,
      async (url, options) => {
        const accessToken = WindowService.getAccessToken();
        Store.setValue(Store.KEYS.AUTHORIZATION, accessToken);
        return fetch(url, {
          ...options,
          headers: { ...options.headers, Authorization: accessToken },
        });
      },
    ),
};

export default InterceptorService;
