import React, { memo } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DocumentUploaderModal from '../documentuploader';
import UpdateCredentialsModal from '../updatecredentials';
import ViewerModal from '../viewermodal';

export const HomePage = () => (
  <>
    <DocumentUploaderModal />
    <UpdateCredentialsModal />
    <ViewerModal />
  </>
);

const withConnect = connect(null, null);

export default hot(compose(withConnect, memo)(HomePage));
